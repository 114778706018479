<template>
  <Submenu v-if="hasOneShowingChildren(menuItem.children) && hasPermission(menuItem) && !menuItem.hidden" :name="menuItem.path || menuItem.name">
    <template slot="title">
      <svg-icon
        v-if="menuItem.meta && menuItem.meta.icon"
        :icon-class="menuItem.meta.icon"
      >
      </svg-icon>
      <span v-if="menuItem.meta && menuItem.meta.title" :title="menuItem.meta.title">{{ menuItem.meta.title }}</span>
    </template>
    <template v-for="child in menuItem.children">
      <!-- If child only visiable in dev, or has hidden attr-->
      <template v-if="hasMenuPermission(child) && !child.hidden && !child.children">
        <MenuItem :key="child.path" :name="`${menuItem.path}/${child.path}`" :to="`${menuItem.path}/${child.path}`" :active-name="$route.path">
          <svg-icon
            v-if="child.meta && child.meta.icon"
            :icon-class="child.meta.icon"
          >
          </svg-icon>
          <span v-if="child.meta && child.meta.title" class="subMenu" :title="child.meta.title">{{ child.meta.title }}</span>
        </MenuItem>
      </template>
    </template>
  </Submenu>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    menuItem: {
      type: Object,
      required: true
    },
    hasPermission: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    sidebar() {
      // 侧边导航栏
      return this.parentPath
    }
  },
  created() {
  },
  methods: {
    // 判断是否有需要显示的子菜单
    hasOneShowingChildren(children) {
      if (!children) return false
      return children.filter(item => {
        return !item.hidden && this.hasMenuPermission(item)
      }).length >= 1
    },
    hasMenuPermission(route) {
      if (this.$store.getters.userInfo.rol && this.$store.getters.userInfo.rol.includes('sysadmin')) {
        return true
      } else if (route.meta && route.meta.permissionId && this.$store.getters.userInfo.rol) {
        return this.$store.getters.userInfo.rol.includes(route.meta.permissionId)
      } else if (this.$store.getters.userInfo.rol === undefined) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .subMenu {
    width: inherit;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
