import { pdiReport, getSalesRegionList, getDealerNameList, damageReport, getDealerGroupList, dealerReport, subjectReport, versionReport, getDamagePartList, getDamageTypeList, getResponsibilityList, topDamageReport, getDamageLevelList, getProvinceList, damageDashboardReport, questionAnalysisReport } from '@/apis/report'
import { download } from '@/utils/download'
import moment from 'moment'
import _ from 'lodash'

/**
 * 初始值
 */
const state = {
  salesRegionList: [],
  dealerNameList: [],
  dealerGroupList: [],
  damagePartList: [],
  damageTypeList: [],
  responsibilityList: [],
  damageLevelList: [],
  provinceList: []
}
const namespaced = true

/**
 * 请求后台方法
 */

const actions = {
  async pdiReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(pdiReport, data, 'PDI总表-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async getSalesRegionList({ commit }, queryParams) {
    const { data } = await getSalesRegionList()
    commit('SET_SALES_REGION_LIST', data)
    return data
  },
  async getResponsibilityList({ commit }, queryParams) {
    const { data } = await getResponsibilityList()
    commit('SET_RESPONSIBILITY_LIST', data)
    return data
  },
  async getDealerNameList({ commit }, queryParams) {
    const { data } = await getDealerNameList()
    commit('SET_DEALER_NAME_LIST', data)
    return data
  },
  async getDamagePartList({ commit }, queryParams) {
    const { data } = await getDamagePartList()
    commit('SET_DAMAGE_PART_LIST', data)
    return data
  },
  async getDamageTypeList({ commit }, queryParams) {
    const { data } = await getDamageTypeList()
    commit('SET_DAMAGE_TYPE_LIST', data)
    return data
  },
  async getDamageLevelList({ commit }, queryParams) {
    const { data } = await getDamageLevelList()
    commit('SET_DAMAGE_LEVEL_LIST', data)
    return data
  },
  async getProvinceList({ commit }, queryParams) {
    const { data } = await getProvinceList()
    commit('SET_PROVINCE_LIST', data)
    return data
  },
  async damageReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(damageReport, data, '质损报告-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async getDealerGroupList({ commit }, queryParams) {
    const { data } = await getDealerGroupList()
    commit('SET_DEALER_GROUP_LIST', data)
    return data
  },
  async dealerReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(dealerReport, data, 'Dealer Report-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async subjectReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(subjectReport, data, 'PDI检查项-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async topDamageReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    const modeName = _.isEmpty(queryParams.vehicleMadeType) ? 'PbP&CBU' : queryParams.vehicleMadeType
    download.save(topDamageReport, data, 'Top 10 Damage-' + modeName + '-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async versionReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = { versionId: queryParams.versionId }
    download.save(versionReport, data, queryParams.versionName + '-' + moment().format('YYYY-MM-DD') + '.pdf')
  },
  async damageDashboardReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(damageDashboardReport, data, 'damage dashboard raw data-' + moment().format('YYYY-MM-DD') + '.xlsx')
  },
  async questionAnalysisReport({
    getters,
    commit,
    dispatch,
    state
  }, queryParams) {
    const data = queryParams
    download.save(questionAnalysisReport, data, '题目dashboard原始数据-' + moment().format('YYYY-MM-DD') + '.xlsx')
  }
}

/**
 * 复制
 */
const mutations = {
  SET_SALES_REGION_LIST(state, data) {
    state.salesRegionList = data
  },
  SET_DEALER_NAME_LIST(state, data) {
    state.dealerNameList = data
  },
  SET_DEALER_GROUP_LIST(state, data) {
    state.dealerGroupList = data
  },
  SET_DAMAGE_PART_LIST(state, data) {
    state.damagePartList = data
  },
  SET_DAMAGE_TYPE_LIST(state, data) {
    state.damageTypeList = data
  },
  SET_RESPONSIBILITY_LIST(state, data) {
    state.responsibilityList = data
  },
  SET_DAMAGE_LEVEL_LIST(state, data) {
    state.damageLevelList = data
  },
  SET_PROVINCE_LIST(state, data) {
    state.provinceList = data
  }
}
const getters = {
  salesRegionList: state => {
    return state.salesRegionList
  },
  dealerNameList: state => {
    return state.dealerNameList
  },
  dealerGroupList: state => {
    return state.dealerGroupList
  },
  damagePartList: state => {
    return state.damagePartList
  },
  damageTypeList: state => {
    return state.damageTypeList
  },
  responsibilityList: state => {
    return state.responsibilityList
  },
  damageLevelList: state => {
    return state.damageLevelList
  },
  provinceList: state => {
    return state.provinceList
  }
}
/**
 * 返回的数据
 */
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced
}
