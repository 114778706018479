<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'App',
  mounted() {
    if (moment().isSame(moment('2020/04/04'), 'day')) {
      document.querySelector('html').style.filter = 'grayscale(1)'
    }
  }
}
</script>
<style>
  html {
    font-size: 62.5%;
  }
</style>

