import { getNoticeItemList, checkNotice, deleteNotice } from '@/apis/notice'
const namespaced = true
/**
 * 初始值
 */
const state = {
  noticeItemList: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async getNoticeItemList({ commit }, queryParams) {
    const { data } = await getNoticeItemList()
    commit('SET_NOTICE_ITEM_LIST_INFO', data)
    return data
  },
  async checkNotice({ commit }, queryParams) {
    const { data } = await checkNotice(queryParams)
    return data
  },
  async deleteNotice({ commit }, queryParams) {
    const { data } = await deleteNotice(queryParams)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_NOTICE_ITEM_LIST_INFO(state, data) {
    state.noticeItemList = data
  }
}
const getters = {
  noticeItemList: state => {
    return state.noticeItemList
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

