// Main
import Vue from 'vue'
import iView from 'view-design' // iview 插件 库
import App from './App'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import { initI18n } from './lang' // 国际化
import { getDeviceType } from '@/utils'

const deviceType = getDeviceType()
// Styles
import 'normalize.css/normalize.css'// A modern alternative to CSS resets
import 'view-design/dist/styles/iview.css' // 插件库样式
import './styles/variable.less' // iview custom style
import './styles/iview.less' // iview custom style
import './styles/index.less' // global css
if (deviceType === 'mobile') {
  // layout css
  const VueTouch = require('vue-touch')
  Vue.use(VueTouch, { name: 'v-touch' })
} else {
  require('./styles/layout.less')
}
// Other
import './errorLog' // 错误日志
import './permission' // layout 权限
import './icons' // icon

// *************************************
//            Plugins
// *************************************
import vuescroll from 'vuescroll/dist/vuescroll-native'
import 'vuescroll/dist/vuescroll.css'
import permission from './directive/permission/index' // 权限指令
import download from '@/utils/download'

// import Logger from '@/utils/logger'
import VueDND from 'awe-dnd'
Vue.use(VueDND)
Vue.use(download)
Vue.use(vuescroll, {
  ops: {
    mode: 'native',
    sizeStrategy: 'percent',
    detectResize: true,
    scrollPanel: {
      initialScrollY: false,
      initialScrollX: false,
      scrollingX: false,
      scrollingY: true,
      speed: 300,
      easing: undefined,
      padding: false,
      verticalNativeBarPos: 'right'
    },
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: false,
      keepShow: false,
      background: '#c8c8c8',
      opacity: 1,
      hoverStyle: false
    }
  },
  name: 'opsScroll'
})
Vue.use(permission)

// *************************************
//            Vue Prototype
// *************************************
import moment from 'moment' // 引用moment
import _ from 'lodash' // 引用lodash

Vue.prototype.$moment = moment // 全局注册moment
Vue.prototype.$_ = _ // 全局注册lodash
Vue.use(VueRouter)
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.prototype.$back = (target) => {
  const from = {
    name: store.getters.user.router.from.name,
    params: store.getters.user.router.from.params,
    query: store.getters.user.router.from.query
  }
  const defaultBack = target || '/home'
  router.push(from.name ? from : defaultBack)
}

// *************************************
//            Components
// Global components, it is no necessary
// to import in each .vue file
// *************************************
import SvgIcon from '@/components/SvgIcon' // 全局引用svg图标
import DropdownWrapper from '@/components/Wrapper/dropdown-wrapper'
import Loading from '@/components/Loading'
import Table from '@/components/Table/table'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)

Vue.component('OpsLoading', Loading)
Vue.component('SvgIcon', SvgIcon)
Vue.component('DWrapper', DropdownWrapper)

import cascaderMulti from 'cascader-multi'
Vue.use(cascaderMulti)
// *************************************
//            Filters
// *************************************
import * as filters from './filters' // global filters

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// *************************************
//            Service Workers
// *************************************
// import sw from './sw'
// sw.initWorker()

// *************************************
//            Vue config
// *************************************
Vue.config.productionTip = false
Vue.config.performance = true

initVue()

function initVue() {
  initI18n(({ i18n }) => {
    Vue.use(iView, {
      i18n: (key, value) => i18n.t(key, value),
      transfer: true,
      modal: {
        maskClosable: false
      }
    })
    Vue.prototype.$Message.config({
      duration: 3
    })
    Vue.component('Table', Table)
    new Vue({
      el: '#app',
      router,
      i18n,
      store,
      render: h => h(App)
    })
  })
}
