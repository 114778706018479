import request from '@/utils/request'

export function getVehicleList(params) {
  return request({
    url: 'business/v1/service/data/vehicle',
    method: 'get',
    params
  })
}

export function checkStructuralModel(params) {
  return request({
    url: 'business/v1/service/data/check-structural-model',
    method: 'get',
    params
  })
}

export function addVehicle(data) {
  return request({
    url: 'business/v1/service/data/vehicle',
    method: 'post',
    data
  })
}
export function searchVehicle(params) {
  return request({
    url: 'business/v1/service/data/search-vehicle',
    method: 'get',
    params
  })
}
export function editVehicle(data) {
  return request({
    url: 'business/v1/service/data/vehicle',
    method: 'put',
    data
  })
}
export function exportVehicle(params) {
  return request({
    url: 'business/v1/service/data/vehicle/export',
    method: 'get',
    responseType: 'arraybuffer',
    params
  })
}
