import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import i_en from 'view-design/dist/locale/en-US'
import i_zh from 'view-design/dist/locale/zh-CN'

Vue.use(VueI18n)

const messages = {
  en: { ...i_en },
  zh: { ...i_zh }
}

let i18n = null
const loadI18n = async function(locale) {
  // const res = await import(
  //   /* webpackChunkName: "i18n_[request]" */
  //   /* webpackMode: "lazy" */
  //   `@/lang/${locale}.js`
  // )
  const res = await Promise.resolve(require(`@/lang/${locale}`))
  i18n = i18n || new VueI18n({
    locale, // set locale
    messages // set locale messages
  })
  let i18nMessage = {}
  if (res && res.default) {
    i18n.mergeLocaleMessage(locale, res.default)
    i18nMessage = res.default
  } else {
    console.error('国际化文件加载失败')
  }
  return { i18n, i18nMessage }
}

const changeLanguage = async function(name) {
  return await loadI18n(name)
}

const initI18n = async function(callback) {
  const locale = Cookies.get('language') || 'en'
  const result = await loadI18n(locale)
  callback && callback(result)
}
export { changeLanguage, initI18n }
export default i18n
