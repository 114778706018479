import Cookies from 'js-cookie'
import { constantRouterMap } from '@/router'

const MENU_KEY = 'current-route'

const state = {
  sidebar: {
    opened: !+Cookies.get('sidebarStatus') // 侧边栏状态
  },
  device: 'desktop', // 默认设备
  language: Cookies.get('language') || 'en', // 默认语言
  currentMenu: null
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
    state.sidebar.opened = !state.sidebar.opened
  },
  CLOSE_SIDEBAR: (state) => {
    Cookies.set('sidebarStatus', 1)
    state.sidebar.opened = false
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_CURRENT_MENU: (state, menu) => {
    // 为了防止用户刷新导致导航丢失
    sessionStorage.setItem(MENU_KEY, menu.path)
    state.currentMenu = menu
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }) {
    commit('CLOSE_SIDEBAR')
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setCurrentMenu({ commit }, menu) {
    commit('SET_CURRENT_MENU', menu)
  }
}

const getters = {
  currentMenu: state => {
    return state.currentMenu ? state.currentMenu : constantRouterMap.find(item => item.path === sessionStorage.getItem(MENU_KEY))
  },
  device: state => state.device
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
