import { getVehicleList, checkStructuralModel, addVehicle, searchVehicle, editVehicle, exportVehicle } from '@/apis/carDataMgt'
import { download } from '@/utils/download'
import moment from 'moment'
const namespaced = true
/**
 * 初始值
 */
const state = {
  vehicleInfo: {},
  vehicle: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async selectVehicleList({ commit }, queryParams) {
    const { data } = await getVehicleList(queryParams)
    commit('SET_VEHICLE_INFO', data)
    return data
  },
  async checkStructuralModel({ commit }, queryParams) {
    const { data } = await checkStructuralModel(queryParams)
    commit('SET_VEHICLE_INFO', data)
    return data
  },
  async addVehicle({ commit }, queryParams) {
    const { data } = await addVehicle(queryParams)
    return data
  },
  async searchVehicle({ commit }, queryParams) {
    const { data } = await searchVehicle(queryParams)
    commit('SET_VEHICLE', data)
    return data
  },
  async editVehicle({ commit }, queryParams) {
    const { data } = await editVehicle(queryParams)
    return data
  },
  async exportVehicle({ commit }, queryParams) {
    download.save(exportVehicle, queryParams, '车型信息列表-' + moment().format('YYYY-MM-DD') + '.xlsx')
  }
}
/**
 * 复制
 */
const mutations = {
  SET_VEHICLE_INFO(state, data) {
    state.vehicleInfo = data
  },
  SET_VEHICLE(state, data) {
    state.vehicle = data
  }
}
const getters = {
  vehicleInfo: state => {
    return state.vehicleInfo
  },
  vehicle: state => {
    return state.vehicle
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

