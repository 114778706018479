<template>
  <div class="layout">
    <Header>
      <div class="layout-img"><img src="@/assets/login_logo.png"></div>
      <div class="breadcrumb-container">
        <Breadcrumb :separator="path !== ''? '>':''">
          <BreadcrumbItem v-if="changeRoute && path.name">
            <svg-icon v-if="path.meta && path.meta.icon" :icon-class="path.meta.icon"></svg-icon>
            {{ path.name }}
          </BreadcrumbItem>
          <BreadcrumbItem v-if="$route.meta && $route.meta.parent" :to="toGroup">
            <svg-icon v-if="path.meta && path.meta.icon && !path.name" :icon-class="path.meta.icon"></svg-icon>{{ parent.name }}
          </BreadcrumbItem>
          <BreadcrumbItem v-if="!$route.meta.parent" :to="$route">
            <svg-icon v-if="path.meta && path.meta.icon && !path.name" :icon-class="path.meta.icon"></svg-icon>{{ $route.name }}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div class="layout-user">
        <download-list class="menu-items"></download-list>
        <div class="notice-icon">
          <Poptip
            placement="bottom"
            width="355"
            @on-popper-show="getNoticeItemList()"
          >
            <Badge :dot="showDot">
              <Icon type="ios-notifications-outline" size="26"></Icon>
            </Badge>
            <div slot="content">
              <notice-list
                :notice-list="noticeList"
                @check-notice="checkNotice"
                @delete-notice="deleteNotice"
                @change-dot="changeDot"
              ></notice-list>
            </div>
          </Poptip>
        </div>
        <Poptip>
          <Avatar icon="ios-person" />
          <span>{{ $store.getters.userInfo && $store.getters.userInfo.description? $store.getters.userInfo.description : $store.getters.userInfo.unm }}</span>
          <div slot="content">
            <a @click="logout"><svg-icon icon-class="log-out"></svg-icon><span>退出登陆</span></a>
          </div>
        </Poptip>
      </div>
    </Header>
    <Layout class="layout-menu">
      <Sider v-model="isCollapsed" breakpoint="md" collapsible :collapsed-width="68">
        <Menu
          ref="menuList"
          :active-name="$route.meta.parent ? path.path + '/' + parent.path:$route.path"
          :open-names="openNames"
          theme="dark"
          width="auto"
          accordion
          :class="menuitemClasses"
          @on-select="choseItem"
          @on-open-change="openSubmenu"
        >
          <template v-for="item in constantRouterMap">
            <!-- 如果没有子菜单且侧边栏没收起 -->
            <MenuItem
              v-if="(!hasOneShowingChildren(item.children) || item.parentHidden) && hasMenuPermission(item) && !item.hidden && !isCollapsed"
              :key="item.name"
              :to="item.path"
              :active-name="$route.path"
              :name="item.path"
              :class="menuitemClasses"
            >
              <svg-icon
                v-if="item.meta && item.meta.icon"
                :icon-class="item.meta.icon"
              >
              </svg-icon>
              <span>{{ getTitle(item) }}</span>
            </MenuItem>
            <!-- 如果有子菜单且侧边栏没有收起 -->
            <side-menu
              v-else-if="!isCollapsed"
              :key="item.path"
              :menu-item="item"
              :active-name="$route.path"
              :has-permission="hasMenuPermission"
            ></side-menu>
            <!-- 不展开有子菜单-->
            <Dropdown
              v-else-if="!item.hidden"
              :key="item.path"
              trigger="click"
              placement="right-start"
              style="margin-left:2px;"
            >
              <a v-if="hasOneShowingChildren(item.children) && hasMenuPermission(item)" href="javascript:void(0)" class="dropdown">
                <svg-icon
                  v-if="item.meta && item.meta.icon"
                  :icon-class="item.meta.icon"
                >
                </svg-icon>
                <span>{{ item.name }}</span>
              </a>
              <DropdownMenu
                v-for="(children, index) in item.children"
                :key="index"
                slot="list"
              >
                <DropdownItem
                  v-if="hasMenuPermission(children) && !children.hidden"
                  class="menu-dropdown"
                  style="margin: 0;color:#fff;"
                >
                  <MenuItem :name="children.path" :to="children" :active-name="$route.path">{{ getTitle(children) }}</MenuItem>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
        </Menu>
      </Sider>
    </Layout>
    <Layout>
      <Content :style="{padding: '16px'}" :class="isCollapsed ? 'collapsed-page-container' : ''">
        <div ref="pageContainer" class="page-container" :class="{'noSider' : noSider}" @click="$store.dispatch('app/closeSideBar')">
          <transition mode="out-in" name="page">
            <keep-alive>
              <router-view :key="$route.fullPath" />
            </keep-alive>
          </transition>
        </div>
      </Content>
    </Layout>
  </div>
</template>

<script>
import sideMenu from './components/side-menu'
import { mapGetters } from 'vuex'
import { constantRouterMap } from '@/router/index'
import DownloadList from './components/DownloadList'
import settings from '@/settings'
import { removeToken } from '@/utils/auth'
import NoticeList from './components/NoticeList'

export default {
  name: 'PdiLayout',
  components: {
    sideMenu,
    DownloadList,
    NoticeList
  },
  data() {
    return {
      constantRouterMap,
      openName: [],
      isSso: process.env.VUE_APP_LOGIN_TYPE === 'sso',
      isCollapsed: false,
      path: '',
      parent: '',
      openNames: ['/' + this.$route.path.split('/')[1]],
      noticeList: [],
      showDot: true
    }
  }, // 混入resize
  computed: {
    menuitemClasses: function() {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    },
    ...mapGetters({
      currentMenu: 'app/currentMenu'
    }),
    sidebar() {
      // 侧边导航栏
      return this.currentMenu
    },
    noSider() {
      return this.$route.meta && this.$route.meta.noSider
    },
    toGroup() {
      return this.path.path + '/' + this.parent.path
    },
    changeRoute() {
      const _this = this
      _this.path = ''
      _this.constantRouterMap.forEach(item => {
        if (item.path === _this.$route.path || item.redirect === _this.$route.path) {
          _this.path = item
          return
        } else if (item.children !== undefined && item.children.length > 0) {
          _this.path = _this.$route.matched[0]
          if (_this.$route.meta && _this.$route.meta.parent) {
            item.children.forEach(element => {
              if (element.path === _this.$route.meta.parent) {
                _this.parent = element
                return
              }
            })
          }
          return
        }
      })
      return _this.path
    }
  },
  watch: {
    isCollapsed() {
      this.openNames = ['/' + this.$route.path.split('/')[1]]
      this.$nextTick(() => {
        this.$refs.menuList.updateOpened()
        this.$refs.menuList.updateActiveName()
      })
    },
    openNames() {
      this.$nextTick(() => {
        this.$refs.menuList.updateOpened()
        this.$refs.menuList.updateActiveName()
      })
    }
  },
  mounted() {
    if (this.inputTimeout) {
      clearInterval(this.inputTimeout)
    } else {
      this.inputTimeout = setTimeout(() => {
        this.getNoticeItemList()
      }, 20000)
    }
    if (this.timer) {
      clearInterval(this.timer)
    } else {
      this.timer = setInterval(() => {
        this.getNoticeItemList()
      }, 600000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    hasMenuPermission(route) {
      if (this.$store.getters.userInfo.rol && this.$store.getters.userInfo.rol.includes('sysadmin')) {
        return true
      } else if (route.meta && route.meta.permissionId && this.$store.getters.userInfo.rol) {
        return this.$store.getters.userInfo.rol.includes(route.meta.permissionId)
      } else if (this.$store.getters.userInfo.rol === undefined) {
        return false
      } else {
        return true
      }
    },
    // 判断是否有需要显示的子菜单
    hasOneShowingChildren(children) {
      if (!children) return false
      return children.filter(item => {
        return !item.hidden && (this.hasMenuPermission(item) || !this.isCollapsed)
      }).length >= 1
    },
    getTitle(item) {
      var title = ''
      if (item.children && item.children[0].meta) {
        title = item.children[0].meta.title
      } else if (item.meta) {
        title = item.meta.title
      }
      return title
    },
    // 选中菜单项
    choseItem(name) {
      this.$nextTick(() => {
        this.$refs.menuList.updateOpened()
        this.$refs.menuList.updateActiveName()
      })
    },
    // 展开子菜单
    openSubmenu(name) {
      this.$nextTick(() => {
        this.$refs.menuList.updateOpened()
        this.$refs.menuList.updateActiveName()
      })
    },
    // 退出登录
    logout: function() {
      // this.$emit('clickLogout')
      this.$Modal.confirm({
        title: `${this.$t('退出登陆')}`,
        content: `<p>${this.$t('确定要退出登陆？')}</p>`,
        onOk: () => {
          if (this.isSso) {
            this.$store.dispatch('login/logout').then(res => {
              if (res) {
                window.location.href = res.data.url
                window[settings.tokenLocation].clear()
                removeToken()
              }
            })
          } else {
            window[settings.tokenLocation].clear()
            removeToken()
            this.close()
          }
        },
        onCancel: () => {}
      })
    },
    close() {
      this.$router.push('/login')
    },
    getNoticeItemList() {
      this.$store.dispatch('notice/getNoticeItemList').then(res => {
        this.noticeList = res
      })
    },
    checkNotice(itemId) {
      this.$store.dispatch('notice/checkNotice', { noticeId: itemId })
    },
    deleteNotice(itemId) {
      this.$store.dispatch('notice/deleteNotice', { noticeId: itemId })
    },
    changeDot(flag) {
      this.showDot = flag
    }
  }
}

</script>

<style scoped lang="less">
  .scroll-area {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .dropdown {
    padding: 14px 24px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    height: 50px;
    line-height: 50px;
  }
  // 路由切换效果
  .page-enter-active,
  .page-leave-active {
    transition: all .25s ease-in-out;
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  .page-enter,
  .page-leave-to {
    transform: translate3d(0,5%,0);
    opacity: 0;
  }

  .back-to-top {
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
  }

  .layout-user {
    .notice-icon {
      display: inline-block;
      cursor: pointer;
      width: 35px;
      vertical-align: sub;
      margin-right: 38px;
      margin-left: 35px;
    }
    ::v-deep.ivu-badge {
      .ivu-badge-dot {
        top: 13px;
        right: -4px;
      }
    }
  }

</style>
