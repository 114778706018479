<template>
  <div class="d-item">
    <p>{{ file.fileName }}</p>
    <Progress :percent="percent" :status="file.status" hide-info />
    <a style="color: red" @click="file.retryFn()">
      <Icon v-if="file.status === 'wrong'" type="md-refresh" />
    </a>
  </div>
</template>
<script>
export default {
  name: 'DownloadItem',
  props: {
    file: {
      type: Object,
      required: true,
      defualt: _ => {}
    }
  },
  data() {
    return {
      percent: 1,
      intervalObj: {}
    }
  },
  mounted() {
    this.intervalObj = setInterval(() => {
      if (this.file.status === 'wrong') {
        this.percent = 100
      }
      if (this.percent < 99) {
        this.file
        this.percent += Math.random() * 10
        if (this.percent > 100) this.percent = 95
      } else {
        clearInterval(this.intervalObj)
      }
    }, 800)
  },
  beforeDestroy() {
    clearInterval(this.intervalObj)
  }
}
</script>
<style lang="less" scoped>
.d-item {
  width: 90%;
}
</style>
