import setting from '@/settings'

/**
 * @returns {string | null}
 */
export function getToken() {
  return window[setting.tokenLocation].getItem(setting.tokenKey)
}
export function setToken(token) {
  return window[setting.tokenLocation].setItem(setting.tokenKey, token)
}
export function removeToken() {
  return window[setting.tokenLocation].removeItem(setting.tokenKey)
}
