import { getToken } from '../../utils/auth'
import { checkTokens, logout } from '@/apis/login'
import router from '@/router'
const namespaced = true
/**
 * 初始值
 */
const state = {
  userInfo: localStorage.getItem('userInfo') !== '' && localStorage.getItem('userInfo') !== 'undefined' && localStorage.getItem('userInfo') !== null
    ? JSON.parse(localStorage.getItem('userInfo')) : {},
  checkTokens: false,
  logoutUrl: null
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async pdiLogin({ commit }, queryParams) {
    var userMsgObj
    var token = getToken()
    if (token !== '' && token !== undefined && token !== null) {
      var newValue = token.split('.')[1]
      var res = decodeURIComponent(escape(window.atob(newValue.replace(/-/g, '+').replace(/_/g, '/'))))
      userMsgObj = JSON.parse(res)
    }
    commit('SET_USERINFO', userMsgObj)
    localStorage.setItem('userInfo', JSON.stringify(userMsgObj))
    return userMsgObj
  },
  async checkTokens({ commit }, queryParams) {
    var token = getToken()
    var check
    if (token !== '' && token !== undefined && token !== null) {
      var data = await checkTokens({ token: token.split('Bearer:')[1] })
      check = data
    } else {
      router.push('/login')
      check = false
    }
    commit('SET_CHECK_TOKENS', check)
    return check
  },
  async logout({ commit }, queryParams) {
    var data = await logout()
    commit('SET_LOGOUT_URL', data)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_USERINFO(state, data) {
    state.userInfo = data
  },
  SET_CHECK_TOKENS(state, data) {
    state.checkTokens = data
  },
  SET_LOGOUT_URL(state, data) {
    state.logoutUrl = data
  }
}
const getters = {
  userInfo: state => state.userInfo,
  checkTokens: state => state.checkTokens,
  logoutUrl: state => state.logoutUrl
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

