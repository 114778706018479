<template>
  <div class="wrapper-container">
    <slot :dataList="dataList || originList">
      <Select v-if="type === 's'" :value="tempVal" v-bind="$attrs" @on-change="changeValue">
        <Option v-for="item in dataList || originList" :key="item.id" :value="item[valueAttr]">
          {{ item[labelAttr] }}
        </Option>
      </Select>
      <Dropdown v-if="type === 'd'" v-bind="$attrs" @on-click="changeValue">
        <slot name="dropdown-slot">
          <Button type="primary">
            {{ tempVal }}
            <Icon type="ios-arrow-down"></Icon>
          </Button>
        </slot>
        <DropdownMenu slot="list">
          <DropdownItem v-for="item in dataList || originList" :key="item.id" :name="itemitem[valueAttr]" :selected="tempVal === item[valueAttr]">{{ item[labelAttr] }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </slot>
  </div>
</template>

<script>
/**
 * @version 1.1.0
 *
 * NOTE: 组件显示与否通过prop中的show来控制, 不要在该组件上使用v-if
 *
 */
export default {
  name: 'DropdownWrapper',
  props: {
    // 待请求下拉框 key
    code: {
      type: String,
      default: null
    },
    // 当发生错误时候的回调方法
    onError: {
      type: Function,
      default: null
    },
    // 下拉框值, 留住v-model作双向绑定
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Array,
      default: null
    },
    // 回调函数
    callbacks: {
      type: Array,
      default: () => []
    },
    // 是否强制获取
    force: {
      type: Boolean,
      default: true
    },
    // 默认渲染组件类型
    type: {
      type: String,
      default: 's'
    },
    // 数据筛选方法, 如果需要处理数据, 传入dataFilter方法, 需要返回处理后的数组
    dataFilter: {
      type: Function,
      default: null
    },
    labelAttr: {
      type: String,
      default: 'name'
    },
    valueAttr: {
      type: String,
      default: 'value'
    },
    dataList: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | Function,
      default: null
    }
  },
  data() {
    return {
      originList: [],
      tempVal: null
    }
  },
  watch: {
    value(val, old) {
      this.tempVal = val
    }
  },
  async beforeMount() {
    // 添加请求数据
    this.$dropdown.addReadyData({
      code: this.code,
      callbacks: [
        (res) => {
        // 如果需要处理数据, 传入dataFilter进行数据处理
          this.originList = this.dataFilter
            ? this.dataFilter(res)
            : res
        },
        ...this.callbacks
      ],
      errorCallback: this.onError
    })
  },
  mounted() {
    this.tempVal = this.value
  },
  methods: {
    /**
     * 下拉框值改变时的事件, 用于配合value进行数据双向绑定
     * @param {String} val 当前选项
     */
    changeValue(val) {
      this.tempVal = val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>
