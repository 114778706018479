<!-- eslint-disable -->
<template>
  <table cellspacing="0" cellpadding="0" border="0" :style="styleObject">
    <colgroup>
      <col v-for="(column, index) in columns" :width="setCellWidth(column)">
    </colgroup>
    <tbody :class="[prefixCls + '-tbody']">
    <template v-for="(row, index) in data">
      <table-tr
        :draggable="draggable"
        :row="row"
        :key="rowKey ? row._rowKey : index"
        :prefix-cls="prefixCls"
        @mouseenter.native.stop="handleMouseIn(row._index)"
        @mouseleave.native.stop="handleMouseOut(row._index)"
        @click.native="clickCurrentRow(row._index)"
        @dblclick.native.stop="dblclickCurrentRow(row._index)">
          <!-- td合并个性化-->
          <td
            v-for="column in columns"
            v-show="getCellSpanProperty(column, index, row, data, columns).visible"
            :rowspan="getCellSpanProperty(column, index, row, data, columns).rowspan"
            :colspan="getCellSpanProperty(column, index, row, data, columns).colspan"
            :class="alignCls(column, row)"
          >
            <table-cell
              :fixed="fixed"
              :prefix-cls="prefixCls"
              :row="row"
              :key="column._columnKey"
              :column="column"
              :natural-index="index"
              :index="row._index"
              :checked="rowChecked(row._index)"
              :disabled="rowDisabled(row._index)"
              :expanded="rowExpanded(row._index)"
            ></table-cell>
          </td>
        </table-tr>
        <tr v-if="rowExpanded(row._index)" :class="{[prefixCls + '-expanded-hidden']: fixed}">
          <td :colspan="columns.length" :class="prefixCls + '-expanded-cell'">
            <Expand :key="rowKey ? row._rowKey : index" :row="row" :render="expandRender" :index="row._index"></Expand>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>
// todo :key="row"
import TableTr from 'view-design/src/components/table/table-tr.vue'
import TableCell from 'view-design/src/components/table/cell.vue'
import Expand from 'view-design/src/components/table/expand.js'
import Mixin from 'view-design/src/components/table/mixin'

export default {
  name: 'TableBody',
  components: { TableCell, Expand, TableTr },
  mixins: [Mixin],
  props: {
    prefixCls: {
      type: String,
      default() {
        return null
      }
    },
    styleObject: {
      type: Object,
      default() {
        return null
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }, // rebuildData
    objData: {
      type: Object,
      default() {
        return null
      }
    },
    columnsWidth: {
      type: Object,
      default() {
        return null
      }
    },
    fixed: {
      type: [Boolean, String],
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    expandRender() {
      let render = function() {
        return ''
      }
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i]
        if (column.type && column.type === 'expand') {
          if (column.render) render = column.render
        }
      }
      return render
    }
  },
  methods: {
    rowChecked(_index) {
      return this.objData[_index] && this.objData[_index]._isChecked
    },
    rowDisabled(_index) {
      return this.objData[_index] && this.objData[_index]._isDisabled
    },
    rowExpanded(_index) {
      return this.objData[_index] && this.objData[_index]._isExpanded
    },
    handleMouseIn(_index) {
      this.$parent.handleMouseIn(_index)
    },
    handleMouseOut(_index) {
      this.$parent.handleMouseOut(_index)
    },
    clickCurrentRow(_index) {
      this.$parent.clickCurrentRow(_index)
    },
    dblclickCurrentRow(_index) {
      this.$parent.dblclickCurrentRow(_index)
    },
    /**
         * td合并个性化
         *   1. 获得td合并相关属性
         *   2. column需要指定是否合并属性和key
         * @param index {Number} The number of row data
         * @param data {Object} The data of table
         * @param row {Object} The data of row
         */
    getCellSpanProperty(column, index, row, data, columns) {
      const cellSpanProperty = {
        visible: true,
        rowspan: 1,
        colspan: 1
      }

      if (!column.key) {
        return cellSpanProperty
      }

      // 当前单元格的key
      const key = column.key
      const preColKey = column._index > 0 ? columns[column._index - 1].key : ''
      // 当前单元格的值
      const currentValue = row[key]

      // 需要合并，并且指定合并的列时，进行合并计算
      if (column.mergeRow) {
        // 判断前一行的值是否相同，相同的话不显示
        if (index > 0) {
          if (data[index - 1][key] === currentValue) {
            if (data[index - 1][preColKey] === row[preColKey]) {
              cellSpanProperty.visible = false
            }
          }
        }
      }

      if (column.mergeCol) {
        // 判断前一列的值是否相同，相同的话不显示
        if (column._index > 0) {
          const beforeColumn = columns[column._index - 1]
          if (beforeColumn.key) {
            const beforeColumnValue = row[beforeColumn.key]
            if (beforeColumnValue === currentValue) {
              cellSpanProperty.visible = false
            }
          }
        }
      }

      // 显示时，进一步判断是否需要向下合并td
      if (cellSpanProperty.visible && column.mergeRow) {
        // 合并的行数
        let rowspanNum = 1
        // 多层级合并行
        // if (column.subMergeRow) {
        //   const titleAry = []
        //   let lastColKey = ''
        //   // sub group merge
        //   this.columns.forEach((item, index, ary) => {
        //     titleAry.push(item.key)
        //   })
        //   titleAry.forEach((item, index, ary) => {
        //     if (item === key) {
        //       lastColKey = ary[index - 1]
        //     }
        //   })
        //   data = data.filter(item => {
        //     return item[lastColKey] === row[lastColKey]
        //   })
        // }
        // 遍历当前行之后的数据，查到相同的认为需要合并
        // data.forEach(oneRow => {
        //   const dataValue = oneRow[key] // current row data
        //   const dataRowIndex = oneRow._index // current row index
        //   const preColKey = column._index > 0 ? columns[column._index - 1].key : ''
        //   if (dataRowIndex > index) {
        //     if (dataValue === currentValue) {
        //       if (oneRow[preColKey] === row[preColKey]) {
        //         rowspanNum++
        //       }
        //     }
        //   }
        // })
        for (let i = 0, l = data.length; i < l; i++) {
          const oneRow = data[i]
          const dataValue = oneRow[key] // current row data
          const dataRowIndex = oneRow._index // current row index
          if (dataRowIndex > index) {
            if (dataValue === currentValue) {
              if (oneRow[preColKey] === row[preColKey]) {
                rowspanNum++
              } else {
                break
              }
            }
          }
        }

        cellSpanProperty.rowspan = rowspanNum
      }

      if (cellSpanProperty.visible && column.mergeCol) {
        // 合并的行数
        let colspanNum = 1

        // 遍历当前行之后的数据，查到相同的认为需要合并
        columns.forEach(oneCol => {
          if (!oneCol.key) {
            return false
          }
          if (oneCol._index > column._index) {
            const dataValue = row[oneCol.key]
            // dataValue[visible] = true
            if (dataValue === currentValue) {
              colspanNum++
              // dataValue[visible] = false
            }
          }
        })

        cellSpanProperty.colspan = colspanNum
      }

      return cellSpanProperty
    },
    getSpan(row, column, rowIndex, columnIndex) {
      const fn = this.$parent.spanMethod
      if (typeof fn === 'function') {
        const result = fn({
          row,
          column,
          rowIndex,
          columnIndex
        })
        let rowspan = 1
        let colspan = 1
        if (Array.isArray(result)) {
          rowspan = result[0]
          colspan = result[1]
        } else if (typeof result === 'object') {
          rowspan = result.rowspan
          colspan = result.colspan
        }
        return {
          rowspan,
          colspan
        }
      } else {
        return {}
      }
    },
    showWithSpan(row, column, rowIndex, columnIndex) {
      const result = this.getSpan(row, column, rowIndex, columnIndex)
      return !(('rowspan' in result && result.rowspan === 0) || ('colspan' in result && result.colspan === 0))
    }
  }
}
</script>
