import Vue from 'vue'
import store from '@/store'

/**
 * 权限自定义指令
 * @since 2018-09-27
 * 支持传入自定义权限来补充权限判断
 * 可选传入权限类型:
 * {Promise}
 * {Array}
 * 可选配置项:
 * 默认元素类名将会添加 disabled， 元素属性也会添加disabled属性并设置为true
 * .hidden 元素将会被隐藏(删除)
 *
 */
const permission = Vue.directive('permission', {
  inserted: async(el, binding) => {
    let additional
    if (binding.expression instanceof Promise) {
      additional = await binding.expression.then(res => res.data.data) || []
    } else if (binding.expression instanceof Array) {
      additional = binding.expression
    } else {
      additional = []
    }
    function disableChild(parentEl) {
      if (parentEl.__vue__) {
        parentEl.__vue__.$set(parentEl.__vue__, 'disabled', true)
      } else if (parentEl.hasChildNodes()) {
        parentEl.childNodes.forEach(i => {
          i.disabled = true
          if (i.childNodes) disableChild(i)
        })
      }
    }
    const permissionIds = [... additional, ...store.getters.user.permission.permissionRepositories]
    // 如果用户没有权限
    if (!permissionIds.find(item => {
      return item.repositoryId === binding.value
    })) {
      // 如果是隐藏的话,直接删除该元素,避免用户F12
      if (binding.modifiers.hidden) {
        el.style.display = 'none'
        // el.parentNode.removeChild(el)
      } else {
        // 如果是a标签,用文本来替换a
        if (el.tagName === 'A') {
          const textNode = document.createTextNode(el.text)
          const textEle = document.createElement('span')
          textEle.appendChild(textNode)
          textEle.className = el.className
          el.parentNode.replaceChild(textEle, el)
        } else {
          el.setAttribute('disabled', 'true')
          disableChild(el)
          el.classList.add('disabled')
        }
      }
    }
  }
})
export default permission
