import request from '@/utils/request'

export function getNoticeItemList(params) {
  return request({
    url: 'business/v1/service/notice/list',
    method: 'get',
    params
  })
}
export function checkNotice(params) {
  return request({
    url: 'business/v1/service/notice/modify',
    method: 'put',
    params
  })
}
export function deleteNotice(params) {
  return request({
    url: 'business/v1/service/notice/delete',
    method: 'delete',
    params
  })
}
