import request from '@/utils/request'

export function getOkRateByMadeType(params) {
  return request({
    url: 'statistics/v1/ok-rate/made-type',
    method: 'get',
    params
  })
}

export function getOkRateByRegion(params) {
  return request({
    url: 'statistics/v1/ok-rate/region',
    method: 'get',
    params
  })
}

export function getOkRateByTypeClass(data) {
  return request({
    url: 'statistics/v1/ok-rate/type-class',
    method: 'post',
    data
  })
}

export function getTopTenDamageInfo(data) {
  return request({
    url: 'statistics/v1/damage/analysis',
    method: 'post',
    data
  })
}

export function getPdiOkRateByMonth(data) {
  return request({
    url: 'statistics/v1/ok-rate/trend',
    method: 'post',
    data
  })
}

export function getDamageAmountByMonth(data) {
  return request({
    url: 'statistics/v1/ok-rate/damage-amount',
    method: 'post',
    data
  })
}

export function getQuestionAnalysisInfo(data) {
  return request({
    url: 'statistics/v1/question/analysis',
    method: 'post',
    data
  })
}
