import request from '@/utils/request'
// 用户
// 账号登陆
export function login(data) {
  return request({
    url: `auth/tokens`,
    method: 'POST',
    data
  })
}
// 验证token
export function checkTokens(params) {
  return request({
    url: `auth/tokens`,
    method: 'get',
    params
  })
}
// 登出
export function logout() {
  return request({
    url: `openid/sso/logout`,
    method: 'POST'
  })
}
