import { saveAs } from 'file-saver'
import _ from 'lodash'

let uid = 1
/**
 * return the file type of extension
 * @param extension
 * @returns {string}
 */
const fileTypeMap = (extension) => {
  switch (extension) {
    case 'xlsx':
      return 'application/vnd.ms-excel'
    default:
      return ''
  }
}

class Download {
  fileList = []
  historyList = []
  watchers = {}
  /**
   * save file
   * @param request the request promise
   * @param fileName the file name to save
   * @param callback the function callback
   */
  save(requestFn, requestParams, fileName, callback, errorCallback = null) {
    if (!_.isString(fileName) || !fileName.includes('.') || !!fileName.match(/[\\\\/:*?\"<>|]/g)) {
      throw new Error(`Invalid file name -- ${fileName} !`)
    }
    this.downloadFn(requestFn, requestParams, fileName, callback, errorCallback)
  }
  /**
   * save file
   * @param request the request promise
   * @param fileName the file name to save
   * @param callback the function callback
   */
  pipelineSave(requestFn, requestParams, fileName, callback, errorCallback = null) {
    if (!_.isString(fileName) || !fileName.includes('.') || !!fileName.match(/[\\\\/:*?\"<>|]/g)) {
      throw new Error(`Invalid file name -- ${fileName} !`)
    }
    this.downloadFn(requestFn, requestParams, fileName, callback, errorCallback)
  }
  async downloadFn(requestFn, requestParams, fileName, callback, errorCallback) {
    uid++
    const extension = _.last(fileName.split('.'))
    // FIXME: ID
    this.fileList.push({
      id: uid,
      fileName,
      type: extension,
      status: 'active',
      retryFn: () => {
        this.fileList.splice(this.fileList.findIndex(item => item.id === uid), 1)
        this.downloadFn(requestFn, requestParams, fileName, callback, errorCallback)
      }
    })
    const watchersKey = Object.keys(this.watchers)
    watchersKey.forEach(key => {
      this.watchers[key](this)
    })
    try {
      const { data } = await requestFn(requestParams)
      if (data) {
        const blob = new Blob([data], { type: fileTypeMap(extension) })
        saveAs(blob, fileName)
        const [removedItem] = this.fileList.splice(this.fileList.findIndex(item => item.id === uid), 1)
        callback && callback()
        this.historyList.push({
          fileName: removedItem.fileName,
          time: Date.now(),
          type: extension
        })
      }
    } catch (err) {
      const errorItem = this.fileList.find(item => item.id === uid)
      errorItem.status = 'wrong'
      errorCallback && errorCallback(err)
    } finally {
      watchersKey.forEach(key => {
        this.watchers[key](this)
      })
    }
  }
}

export const download = new Download()

export default {
  install(vue, options) {
    vue.prototype.$d = download
  }
}
