<!-- eslint-disable -->
<template>
  <table cellspacing="0" cellpadding="0" border="0" :style="styles">
    <colgroup>
      <col v-for="(column, index) in columns" :width="setCellWidth(column)">
      <col v-if="$parent.showVerticalScrollBar" :width="$parent.scrollBarWidth">
    </colgroup>
    <thead>
    <tr v-for="(cols, rowIndex) in headRows">
      <th
        v-for="(column, index) in cols"
        :colspan="column.colSpan"
        :rowspan="column.rowSpan"
        :class="alignCls(column)"
      >
        <div :class="cellClasses(column)">
          <template v-if="column.type === 'expand'">
            <span v-if="!column.renderHeader">{{ column.title || '' }}</span>
            <render-header v-else :render="column.renderHeader" :column="column" :index="index"></render-header>
          </template>
          <template v-else-if="column.type === 'selection'"><Checkbox :value="isSelectAll" :disabled="!data.length" @on-change="selectAll"></Checkbox></template>
          <template v-else>
            <span v-if="!column.renderHeader" :class="{[prefixCls + '-cell-sort']: column.sortable}" @click="handleSortByHead(getColumn(rowIndex, index)._index)">{{ column.title || '#' }}</span>
            <render-header v-else :render="column.renderHeader" :column="column" :index="index"></render-header>
            <span v-if="column.sortable" :class="[prefixCls + '-sort']">
                <i class="ivu-icon ivu-icon-md-arrow-dropup" :class="{on: getColumn(rowIndex, index)._sortType === 'asc'}" @click="handleSort(getColumn(rowIndex, index)._index, 'asc')"></i>
                <i class="ivu-icon ivu-icon-md-arrow-dropdown" :class="{on: getColumn(rowIndex, index)._sortType === 'desc'}" @click="handleSort(getColumn(rowIndex, index)._index, 'desc')"></i>
              </span>
            <Poptip
              v-if="isPopperShow(column)"
              v-model="getColumn(rowIndex, index)._filterVisible"
              placement="bottom"
              popper-class="ivu-table-popper"
              transfer
              @on-popper-hide="handleFilterHide(getColumn(rowIndex, index)._index)"
            >
                <span :class="[prefixCls + '-filter']">
                  <i class="ivu-icon ivu-icon-ios-funnel" :class="{on: getColumn(rowIndex, index)._isFiltered}"></i>
                </span>

              <div v-if="getColumn(rowIndex, index)._filterMultiple" slot="content" :class="[prefixCls + '-filter-list']">
                <div :class="[prefixCls + '-filter-list-item']">
                  <checkbox-group v-model="getColumn(rowIndex, index)._filterChecked">
                    <checkbox v-for="(item, index) in column.filters" :key="index" :label="item.value">{{ item.label }}</checkbox>
                  </checkbox-group>
                </div>
                <div :class="[prefixCls + '-filter-footer']">
                  <i-button type="text" size="small" :disabled="!getColumn(rowIndex, index)._filterChecked.length" @click.native="handleFilter(getColumn(rowIndex, index)._index)">{{ t('i.table.confirmFilter') }}</i-button>
                  <i-button type="text" size="small" @click.native="handleReset(getColumn(rowIndex, index)._index)">{{ t('i.table.resetFilter') }}</i-button>
                </div>
              </div>
              <div v-else slot="content" :class="[prefixCls + '-filter-list']">
                <ul :class="[prefixCls + '-filter-list-single']">
                  <li
                    :class="itemAllClasses(getColumn(rowIndex, index))"
                    @click="handleReset(getColumn(rowIndex, index)._index)"
                  >{{ t('i.table.clearFilter') }}</li>
                  <li
                    v-for="item in column.filters"
                    :class="itemClasses(getColumn(rowIndex, index), item)"
                    @click="handleSelect(getColumn(rowIndex, index)._index, item.value)"
                  >{{ item.label }}</li>
                </ul>
              </div>
            </Poptip>
          </template>
        </div>
      </th>

      <th v-if="$parent.showVerticalScrollBar && rowIndex===0" :class="scrollBarCellClass()" :rowspan="headRows.length"></th>
    </tr>
    </thead>
  </table>
</template>
<script>
import CheckboxGroup from 'view-design/src/components/checkbox/checkbox-group.vue'
import Checkbox from 'view-design/src/components/checkbox/checkbox.vue'
import Poptip from 'view-design/src/components/poptip/poptip.vue'
import iButton from 'view-design/src/components/button/button.vue'
import renderHeader from 'view-design/src/components/table/header'
import Mixin from 'view-design/src/components/table/mixin'
import Locale from 'view-design/src/mixins/locale'

export default {
  name: 'TableHead',
  components: { CheckboxGroup, Checkbox, Poptip, iButton, renderHeader },
  mixins: [Mixin, Locale],
  props: {
    prefixCls: {
      type: String,
      default() {
        return null
      }
    },
    styleObject: {
      type: Object,
      default() {
        return null
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    objData: {
      type: Object,
      default() {
        return null
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }, // rebuildData
    columnsWidth: {
      type: Object,
      default() {
        return null
      }
    },
    fixed: {
      type: [Boolean, String],
      default: false
    },
    columnRows: {
      type: Array,
      default() {
        return []
      }
    },
    fixedColumnRows: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    styles() {
      const style = Object.assign({}, this.styleObject)
      const width = parseInt(this.styleObject.width)
      style.width = `${width}px`
      return style
    },
    isSelectAll() {
      let isSelectAll = true
      if (!this.data.length) isSelectAll = false
      if (!this.data.find(item => !item._disabled)) isSelectAll = false // #1751
      for (let i = 0; i < this.data.length; i++) {
        if (!this.objData[this.data[i]._index]._isChecked && !this.objData[this.data[i]._index]._isDisabled) {
          isSelectAll = false
          break
        }
      }

      return isSelectAll
    },
    headRows() {
      const isGroup = this.columnRows.length > 1
      if (isGroup) {
        return this.fixed ? this.fixedColumnRows : this.columnRows
      } else {
        return [this.columns]
      }
    }
  },
  methods: {
    cellClasses(column) {
      return [
        `${this.prefixCls}-cell`,
        {
          [`${this.prefixCls}-hidden`]: !this.fixed && column.fixed && (column.fixed === 'left' || column.fixed === 'right'),
          [`${this.prefixCls}-cell-with-selection`]: column.type === 'selection'
        }
      ]
    },
    scrollBarCellClass() {
      let hasRightFixed = false
      for (var i in this.headRows) {
        for (var j in this.headRows[i]) {
          if (this.headRows[i][j].fixed === 'right') {
            hasRightFixed = true
            break
          }
          if (hasRightFixed) break
        }
      }
      return [
        {
          [`${this.prefixCls}-hidden`]: hasRightFixed
        }
      ]
    },
    itemClasses(column, item) {
      return [
        `${this.prefixCls}-filter-select-item`,
        {
          [`${this.prefixCls}-filter-select-item-selected`]: column._filterChecked[0] === item.value
        }
      ]
    },
    itemAllClasses(column) {
      return [
        `${this.prefixCls}-filter-select-item`,
        {
          [`${this.prefixCls}-filter-select-item-selected`]: !column._filterChecked.length
        }
      ]
    },
    selectAll() {
      const status = !this.isSelectAll
      this.$parent.selectAll(status)
    },
    handleSort(index, type) {
      const column = this.columns[index]
      const _index = column._index

      if (column._sortType === type) {
        type = 'normal'
      }
      this.$parent.handleSort(_index, type)
    },
    handleSortByHead(index) {
      const column = this.columns[index]
      if (column.sortable) {
        const type = column._sortType
        if (type === 'normal') {
          this.handleSort(index, 'asc')
        } else if (type === 'asc') {
          this.handleSort(index, 'desc')
        } else {
          this.handleSort(index, 'normal')
        }
      }
    },
    handleFilter(index) {
      this.$parent.handleFilter(index)
    },
    handleSelect(index, value) {
      this.$parent.handleFilterSelect(index, value)
    },
    handleReset(index) {
      this.$parent.handleFilterReset(index)
    },
    handleFilterHide(index) {
      this.$parent.handleFilterHide(index)
    },
    // 因为表头嵌套不是深拷贝，所以没有 _ 开头的方法，在 isGroup 下用此列
    getColumn(rowIndex, index) {
      const isGroup = this.columnRows.length > 1

      if (isGroup) {
        const id = this.headRows[rowIndex][index].__id
        return this.columns.filter(item => item.__id === id)[0]
      } else {
        return this.headRows[rowIndex][index]
      }
    }
  }
}
</script>
