import { getPdiDamageDetail, getPdiDetail, getDamageDetail } from '@/apis/lossDetail'
const namespaced = true
/**
 * 初始值
 */
const state = {
  pdiDamageDetailInfo: {},
  pdiDetailInfo: {},
  damageDetailInfo: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async selectPdiDamageDetail({ commit }, queryParams) {
    const { data } = await getPdiDamageDetail(queryParams)
    commit('SET_PDI_DAMAGE_DETAIL_INFO', data)
    return data
  },
  async selectPdiDetail({ commit }, queryParams) {
    const { data } = await getPdiDetail(queryParams)
    commit('SET_PDI_DETAIL_INFO', data)
    return data
  },
  async selectDamageDetail({ commit }, queryParams) {
    const { data } = await getDamageDetail(queryParams)
    commit('SET_DAMAGE_DETAIL_INFO', data)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_PDI_DAMAGE_DETAIL_INFO(state, data) {
    state.pdiDamageDetailInfo = data
  },
  SET_PDI_DETAIL_INFO(state, data) {
    state.pdiDetailInfo = data
  },
  SET_DAMAGE_DETAIL_INFO(state, data) {
    state.damageDetailInfo = data
  }
}
const getters = {
  pdiDamageDetailInfo: state => {
    return state.pdiDamageDetailInfo
  },
  pdiDetailInfo: state => {
    return state.pdiDetailInfo
  },
  damageDetailInfo: state => {
    return state.damageDetailInfo
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

