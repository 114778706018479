import request from '@/utils/request'

export function getPermissionList() {
  return request({
    url: 'auth/roles',
    method: 'get'
  })
}
export function getUserList(params) {
  return request({
    url: 'auth/users/list',
    method: 'get',
    params
  })
}
export function getUserById(params) {
  return request({
    url: `auth/users/${params.id}`,
    method: 'get'
  })
}
export function editUser(data) {
  return request({
    url: 'auth/users',
    method: 'put',
    data
  })
}
export function checkWIW(params) {
  return request({
    url: 'auth/users',
    method: 'get',
    params
  })
}
export function addUser(data) {
  return request({
    url: 'auth/users',
    method: 'post',
    data
  })
}
export function lockUser(params) {
  return request({
    url: `auth/users/lock/${params.id}`,
    method: 'PATCH'
  })
}
export function unlockUser(params) {
  return request({
    url: `auth/users/unlock/${params.id}`,
    method: 'PATCH'
  })
}
