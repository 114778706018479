import router from './router'
import { constantRouterMap } from './router'
import store from './store'
import { LoadingBar, Message } from 'view-design' // 引入iview的加载进度条
import settings from './settings'
import { getToken } from '@/utils/auth'
const TOKEN_KEY__TARGET_PAGE = 'pdi.target.page'
// const path = ''
/**
 * 判断用户有没有该路由的权限
 * @param {Object} to 起始页面路由
 * @param {Object} next 目标页面路由
 * @param {Array} permissionMenuList 用户菜单权限列表
 */
async function judgePermission(to, next, permissionMenuList) {
  // 如果用户有菜单权限列表,即,有用户信息
  if (permissionMenuList && permissionMenuList.length > 0) {
    // 如果路由存在permissionId, 即需要权限控制
    if (to.meta && to.meta.permissionId) {
      // 如果用户没有对应的permissionId
      if (!permissionMenuList.split(',').find(i => i === to.meta.permissionId) && !permissionMenuList.includes('sysadmin')) {
        Message.error('You has no permission to access this page.')
        next(settings.noPermissionRedirect)
        LoadingBar.finish()
        return
      }
    }
    next()
    LoadingBar.finish()
  } else {
    // 如果用户没有菜单权限列表,即 用户没有登录或者其他原因, 调用用户信息
    if (getToken() === '' || getToken() === null || getToken() === undefined) {
      /**
       * 未验证
       * 1. 用户未登录, 没有token
       * 2. 已有token失效
       */
      if (!window.location.hash.includes('login')) {
        router.push('/login')
      }
    } else {
      Message.error('You has no permission to access this page.')
      router.push('/home')
    }
    next()
    LoadingBar.finish()
    // await store.dispatch('login/pdiLogin')
    // // 验证通过
    // const permissionMenuList_new = store.getters.userInfo.rol || null
    // await judgePermission(to, next, permissionMenuList_new)
  }
  // }
}

/**
 * 路由拦截器，路由之前的方法
 * @param {Router} to 起始页面路由
 * @param {Router} from 目标页面路由
 * @param {Function} next 路由通过方法
 */
router.beforeEach(async(to, from, next) => {
  const ssoAuth = async() => {
    const permissionList = store.getters.userInfo.rol || null
    // 如果是sso登录,并且不是sit环境下（sit环境下现在为stage阶段的sso，不含idtoken）
    await judgePermission(to, next, permissionList)
  }
  // 关闭侧边栏
  if (store.getters.sidebar.opened) store.dispatch('app/toggleSideBar')
  LoadingBar.start() // 进度条开始
  // 如果该路由不需要验证权限
  if (settings.noAuthPath.includes(to.path)) {
    next()
    LoadingBar.finish()
  } else {
    await ssoAuth()
  }
})

router.afterEach((to, from) => {
  window[settings.tokenLocation].setItem(TOKEN_KEY__TARGET_PAGE, window.location.href)
  const item = constantRouterMap.find(item => item.path === to.matched[0].path)
  store.dispatch('app/setCurrentMenu', item)
  LoadingBar.finish() // finish progress bar
})
