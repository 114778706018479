import { getLossPosition, getLossArea, getLossPart, getLossPositionAll, getLossAreaLookup, getLossType, getLossDegree } from '@/apis/lossMgt'
const namespaced = true
/**
 * 初始值
 */
const state = {
  positionInfo: {},
  lossAreaInfo: {},
  lossPartInfo: {},
  partLookup: {},
  partPositionList: {},
  lossTypeInfo: {},
  lossDegreeInfo: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async selectLossPosition({ commit }, queryParams) {
    const { data } = await getLossPosition(queryParams)
    commit('SET_POSITION_INFO', data)
    return data
  },
  async selectLossArea({ commit }, queryParams) {
    const { data } = await getLossArea(queryParams)
    commit('SET_AREA_INFO', data)
    return data
  },
  async selectLossPart({ commit }, queryParams) {
    const { data } = await getLossPart(queryParams)
    commit('SET_PART_INFO', data)
    return data
  },
  async selectLossPositionAll({ commit }) {
    const { data } = await getLossPositionAll()
    commit('SET_PART_POSITION_LIST', data)
    return data
  },
  async selectPartLookup({ commit }, queryParams) {
    const { data } = await getLossAreaLookup(queryParams)
    commit('SET_PART_LOOKUP', data)
    return data
  },
  async selectLossType({ commit }, queryParams) {
    const { data } = await getLossType(queryParams)
    commit('SET_LOSS_TYPE_INFO', data)
    return data
  },
  async selectLossDegree({ commit }, queryParams) {
    const { data } = await getLossDegree(queryParams)
    commit('SET_DEGREE_INFO', data)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_POSITION_INFO(state, data) {
    state.positionInfo = data
  },
  SET_AREA_INFO(state, data) {
    state.lossAreaInfo = data
  },
  SET_PART_INFO(state, data) {
    state.lossPartInfo = data
  },
  SET_PART_POSITION_LIST(state, data) {
    state.partPositionList = data
  },
  SET_PART_LOOKUP(state, data) {
    state.partLookup = data
  },
  SET_LOSS_TYPE_INFO(state, data) {
    state.lossTypeInfo = data
  },
  SET_DEGREE_INFO(state, data) {
    state.lossDegreeInfo = data
  }
}
const getters = {
  positionInfo: state => {
    return state.positionInfo
  },
  lossAreaInfo: state => {
    return state.lossAreaInfo
  },
  lossPartInfo: state => {
    return state.lossPartInfo
  },
  partPositionList: state => {
    return state.partPositionList
  },
  partLookup: state => {
    return state.partLookup
  },
  lossTypeInfo: state => {
    return state.lossTypeInfo
  },
  lossDegreeInfo: state => {
    return state.lossDegreeInfo
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

