<template>
  <div class="notice-list">
    <Collapse v-model="spread" class="notice-item">
      <Panel name="1">
        数据缺失提醒
        <Table slot="content" :show-header="false" :columns="columns" :data="noticeList"></Table>
      </Panel>
    </Collapse>
    <Modal v-model="showNoticeDetail" :transfer="false" footer-hide :z-index="rank">
      <div>
        <span class="notice-detail">{{ noticeDetail }}</span>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'NoticeList',
  props: {
    noticeList: {
      type: Array,
      required: true,
      defualt: _ => []
    }
  },
  data() {
    return {
      spread: '1',
      rank: 9999,
      intervalObj: {},
      showNoticeDetail: false,
      noticeDetail: '',
      columns: [
        {
          align: 'center',
          width: 20,
          render: (h, params) => {
            return h('div', [
              h('Icon', {
                props: {
                  type: 'ios-alert'
                },
                style: {
                  'font-size': '20px',
                  color: '#ec921a',
                  'width': '20px',
                  'display': params.row.checkFlag === '1' ? 'none' : 'inline-block',
                  'margin-left': '-8px'
                }
              })
            ])
          }
        },
        {
          key: 'name',
          render: (h, params) => {
            return h('div',
              {
                on: {
                  click: () => {
                    this.openDetail(params.row)
                  }
                }
              },
              [
                h('div',
                  {
                    style: {
                      'width': '230px',
                      cursor: 'pointer'
                    }
                  },
                  [
                    h('div',
                      {
                        style: {
                          'overflow': 'hidden',
                          'text-overflow': 'ellipsis',
                          'display': '-webkit-box',
                          '-webkit-box-orient': 'vertical',
                          '-webkit-line-clamp': '1',
                          'font-size': '12px'
                        }
                      },
                      params.row.noticeContent
                    ),
                    h('div',
                      {
                        style: {
                          'font-size': '8px',
                          color: '#848b99'
                        }
                      },
                      params.row.createdTime
                    )
                  ])
              ])
          }
        },
        {
          align: 'center',
          width: 40,
          render: (h, params) => {
            return h('div', [
              h('Icon', {
                props: {
                  type: 'ios-trash'
                },
                style: {
                  color: '#108bf4',
                  'padding-right': '10px',
                  'font-size': '20px',
                  cursor: 'pointer',
                  'margin-left': '-8px'
                },
                on: {
                  click: () => {
                    this.deleteNoticeItem(params.row)
                  }
                }})
            ])
          }
        }
      ]
    }
  },
  watch: {
    noticeList: {
      immediate: true,
      handler(val) {
        const filterList = val.find(en => en.checkFlag === '0')
        if (_.isNil(filterList)) {
          this.$emit('change-dot', false)
        } else {
          this.$emit('change-dot', true)
        }
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    openDetail(row) {
      this.showNoticeDetail = true
      this.noticeDetail = row.noticeContent
      this.$emit('check-notice', row.id)
      this.noticeList.forEach((item) => {
        if (item.id === row.id) {
          item.checkFlag = '1'
        }
      })
    },
    deleteNoticeItem(row) {
      this.$emit('delete-notice', row.id)
      this.noticeList.splice(this.noticeList.findIndex(item => item.id === row.id), 1)
    }
  }
}
</script>
<style lang="less" scoped>
.notice-list {
  max-height: 223px;
  width: 318px;
  ::v-deep.ivu-collapse-content {
    padding: 0px;
    .ivu-collapse-content-box {
      padding: 0px;
      color: #848b99;
    }
  }
  .notice-detail {
    word-break: break-word;
    white-space: pre-line;
  }
  ::v-deep.ivu-modal-content {
    height: 200px;
    margin-top: 70px;
  }
}
</style>
