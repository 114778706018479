import request from '@/utils/request'

export function getLossPosition(params) {
  return request({
    url: 'business/v1/service/damage/position',
    method: 'get',
    params
  })
}
export function getLossArea(params) {
  return request({
    url: 'business/v1/service/damage/area',
    method: 'get',
    params
  })
}

export function getLossPart(params) {
  return request({
    url: 'business/v1/service/damage/spare-parts',
    method: 'get',
    params
  })
}
export function getLossPositionAll() {
  return request({
    url: 'business/v1/service/damage/position/all',
    method: 'get'
  })
}
export function getLossAreaLookup(params) {
  return request({
    url: 'business/v1/service/damage/search-areas',
    method: 'get',
    params
  })
}
export function getLossType(params) {
  return request({
    url: 'business/v1/service/damage/type',
    method: 'get',
    params
  })
}

export function getLossDegree(params) {
  return request({
    url: 'business/v1/service/damage/severity',
    method: 'get',
    params
  })
}

