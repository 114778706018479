import { getPermissionList, getUserList, getUserById, editUser, checkWIW, addUser, lockUser, unlockUser } from '@/apis/permission'
const namespaced = true
/**
 * 初始值
 */
const state = {
  permissionList: [],
  userList: [],
  userById: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async getPermissionList({ commit }) {
    const { data } = await getPermissionList()
    const list = data.filter(item => item.role.indexOf('otr') === -1)
    commit('SET_PERMISSION_LIST', list)
    return list
  },
  async getUserList({ commit }, queryParams) {
    const { data } = await getUserList(queryParams)
    commit('SET_UESR_LIST', data)
    return data
  },
  async getUserById({ commit }, queryParams) {
    const { data } = await getUserById(queryParams)
    data.name = data.name.toUpperCase()
    commit('SET_USER_BY_ID', data)
    return data
  },
  async editUser({ commit }, queryParams) {
    const { data } = await editUser(queryParams)
    return data
  },
  async checkWIW({ commit }, queryParams) {
    const { data } = await checkWIW(queryParams)
    return data
  },
  async addUser({ commit }, queryParams) {
    const { data } = await addUser(queryParams)
    return data
  },
  async lockUser({ commit }, queryParams) {
    const { data } = await lockUser(queryParams)
    return data
  },
  async unlockUser({ commit }, queryParams) {
    const { data } = await unlockUser(queryParams)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_PERMISSION_LIST(state, data) {
    state.permissionList = data
  },
  SET_UESR_LIST(state, data) {
    state.userList = data
  },
  SET_USER_BY_ID(state, data) {
    state.userById = data
  }
}
const getters = {
  permissionList: state => state.permissionList,
  userList: state => state.userList,
  userById: state => state.userById
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

