import request from '@/utils/request'

export function getQuestionList(params) {
  return request({
    url: 'business/v1/service/question-bank/subject',
    method: 'get',
    params
  })
}

export function getQuestionById(params) {
  return request({
    url: 'business/v1/service/question-bank/search-subject',
    method: 'get',
    params
  })
}

export function editQuestion(data) {
  return request({
    url: 'business/v1/service/question-bank/subject',
    method: 'post',
    data
  })
}

export function getQuestionCheckItemById(params) {
  return request({
    url: 'business/v1/service/question-bank/check-item',
    method: 'get',
    params
  })
}

export function getVersion(params) {
  return request({
    url: 'business/v1/service/question-bank/version',
    method: 'get',
    params
  })
}

export function addVersion(data) {
  return request({
    url: 'business/v1/service/question-bank/version',
    method: 'post',
    data
  })
}
export function getVersionModel() {
  return request({
    url: 'business/v1/service/question-bank/model',
    method: 'get'
  })
}
export function getVersionFuel() {
  return request({
    url: 'business/v1/service/question-bank/fuel',
    method: 'get'
  })
}
export function checkVersionName(params) {
  return request({
    url: 'business/v1/service/question-bank/check-version-name',
    method: 'get',
    params
  })
}
export function searchVersion(params) {
  return request({
    url: 'business/v1/service/question-bank/search-version',
    method: 'get',
    params
  })
}
export function checkVersionModel(data) {
  return request({
    url: 'business/v1/service/question-bank/check-model',
    method: 'post',
    data
  })
}
export function editVersion(data) {
  return request({
    url: 'business/v1/service/question-bank/version',
    method: 'put',
    data
  })
}
export function disabledSubject(params) {
  return request({
    url: `business/v1/service/question-bank/disabled-subject/${params.id}`,
    method: 'PATCH'
  })
}
export function getLabelListInfo() {
  return request({
    url: 'business/v1/service/question-bank/label/all',
    method: 'get'
  })
}
export function getLabelPageList(params) {
  return request({
    url: 'business/v1/service/question-bank/label',
    method: 'get',
    params
  })
}
export function addLabel(params) {
  return request({
    url: 'business/v1/service/question-bank/label/creat',
    method: 'post',
    params
  })
}
export function deleteLabel(params) {
  return request({
    url: 'business/v1/service/question-bank/label/delete',
    method: 'delete',
    params
  })
}
