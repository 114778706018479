<template>
  <div class="download-list">
    <Poptip
      placement="bottom-end"
      :offset="12"
      width="300"
    >
      <Badge :count="messageCount" class="header-badge">
        <svg-icon icon-class="filedownload" />
      </Badge>
      <div slot="content">
        <ul v-if="$d.fileList.length > 0">
          <download-item v-for="file in fileList" :key="file.time" :file="file"></download-item>
        </ul>
        <p v-else style="text-align: center">
          No downloading files
        </p>
      </div>
    </Poptip>
  </div>
</template>

<script>
import DownloadItem from './DownloadItem'
export default {
  name: 'DownloadList',
  components: { DownloadItem },
  data() {
    return {
      messageCount: 0,
      intervals: {},
      fileList: []
    }
  },
  mounted() {
    this.$d.watchers['list'] = (res) => {
      this.fileList = res.fileList
      this.messageCount = res.fileList.length
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.download-list {
  padding-right: 0px !important;
}
</style>
