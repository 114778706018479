<template>
  <spin v-show="loading" :fix="fix">
  </spin>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    fix: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isIE: !!document.documentMode
    }
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>

</style>
