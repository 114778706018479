import variables from '@/styles/variable.less'

class Settings {
  theme = variables.theme
  isSSO = true && process.env.VUE_APP_LOGIN_TYPE === 'sso'
  errorLog = 'production' // 错误信息日志环境判断
  tokenLocation = 'localStorage' // Token 存储位置
  firstEnterPage = '/home' // 首次进入系统页面
  noPermissionRedirect = '/home' // 无权限重定向页面
  toWelcomeWithNoPermission = 'welcome' // 无权限重定向到欢迎页面
  noAuthRedirect = '/login' // 未登录重定向页面
  errorRedirect = '/errorPage' // 错误信息通用重定向页面
  noAuthPath = ['/login', '/home', '/errorPage'] // 无需验证路径列表
  store = {
    strict: false && process.env.NODE_ENV === 'development' // 严格模式
  }
  request = { // 请求设置
    tokenId: 'X-USER-TOKEN', // Header Token key
    timeout: 20 * 60 * 1000, // 请求超时时间
    prefix: 'api/', // 请求前缀
    suffix: '.json', // 请求后缀
    message: { // 请求报错信息
      _403: 'Sorry, you have no permission to access this page.',
      _503: 'Something wrong with the server, please wait for a moment or contact the pdi Team.'
    },
    errorTokenPageKey: 'errorTokenPage'
  }
  tokenKey = 'pdi.token'
}

export default new Settings()
