import { getOkRateByMadeType, getOkRateByRegion, getOkRateByTypeClass, getTopTenDamageInfo, getPdiOkRateByMonth, getDamageAmountByMonth, getQuestionAnalysisInfo } from '@/apis/dashboard'
const namespaced = true
/**
 * 初始值
 */
const state = {
  madeTypeInfo: {},
  regionInfo: {},
  typeClassesInfo: {},
  topTenDamageInfo: {},
  pdiOkRateByMonth: {},
  damageAmountByMonth: {},
  questionAnalysisInfo: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async getOkRateByMadeType({ commit }, queryParams) {
    const { data } = await getOkRateByMadeType(queryParams)
    commit('SET_MADE_TYPE_INFO', data)
    return data
  },
  async getOkRateByRegion({ commit }, queryParams) {
    const { data } = await getOkRateByRegion(queryParams)
    commit('SET_REGION_INFO', data)
    return data
  },
  async getOkRateByTypeClass({ commit }, queryParams) {
    const { data } = await getOkRateByTypeClass(queryParams)
    commit('SET_TYPE_CLASS_INFO', data)
    return data
  },
  async getTopTenDamageInfo({ commit }, queryParams) {
    const { data } = await getTopTenDamageInfo(queryParams)
    commit('SET_TOP_TEN_DAMAGE_INFO', data)
    return data
  },
  async getPdiOkRateByMonth({ commit }, queryParams) {
    const { data } = await getPdiOkRateByMonth(queryParams)
    commit('SET_PDI_OK_RATE_BY_MONTH', data)
    return data
  },
  async getDamageAmountByMonth({ commit }, queryParams) {
    const { data } = await getDamageAmountByMonth(queryParams)
    commit('SET_DAMAGE_AMOUNT_BY_MONTH', data)
    return data
  },
  async getQuestionAnalysisInfo({ commit }, queryParams) {
    const { data } = await getQuestionAnalysisInfo(queryParams)
    commit('SET_QUESTION_ANALYSIS_INFO', data)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_MADE_TYPE_INFO(state, data) {
    state.madeTypeInfo = data
  },
  SET_REGION_INFO(state, data) {
    state.regionInfo = data
  },
  SET_TYPE_CLASS_INFO(state, data) {
    state.typeClassesInfo = data
  },
  SET_TOP_TEN_DAMAGE_INFO(state, data) {
    state.topTenDamageInfo = data
  },
  SET_PDI_OK_RATE_BY_MONTH(state, data) {
    state.pdiOkRateByMonth = data
  },
  SET_DAMAGE_AMOUNT_BY_MONTH(state, data) {
    state.damageAmountByMonth = data
  },
  SET_QUESTION_ANALYSIS_INFO(state, data) {
    state.questionAnalysisInfo = data
  }

}
const getters = {
  madeTypeInfo: state => {
    return state.madeTypeInfo
  },
  regionInfo: state => {
    return state.regionInfo
  },
  typeClassesInfo: state => {
    return state.typeClassesInfo
  },
  topTenDamageInfo: state => {
    return state.topTenDamageInfo
  },
  pdiOkRateByMonth: state => {
    return state.pdiOkRateByMonth
  },
  damageAmountByMonth: state => {
    return state.damageAmountByMonth
  },
  questionAnalysisInfo: state => {
    return state.questionAnalysisInfo
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

