import moment from 'moment'

/**
 * 通过moment.js来格式化时间
 * @param date {any} 传入时间
 * @param format {String} (可选)格式化格式,默认为'DD/MM/YYYY'
 * @param option {Object} (可选)其他设置,目前只是预留位,留作之后扩展
 * @return {String, Error}
 */
export const dateFilter = (date, format, option) => {
  const DEFAULT_TIME_FORMAT = 'YYYY/MM/DD'
  return moment(date).isValid() ? moment(date).format(format || DEFAULT_TIME_FORMAT) : new Error('Invalid Date')
}

/**
 * 用逗号以千分隔数字
 * @param num {String, Number} 待转换数字
 * @return {string} 转换后的字符串数字
 */
export const moneyFilter = (num) => {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
