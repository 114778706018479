import axios from 'axios'
import iView from 'view-design'
import router from '@/router'
import authentication from '@/vendor/adal-vue'

import settings from '@/settings'

import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  timeout: settings.request.timeout, // 定义请求超时时间为30s
  withCredentials: false, // 请求是否带cookie
  baseURL: './'
})
/**
 * 请求拦截器
 * @param {Object} config RequestConfig
 */
service.interceptors.request.use(async config => {
  // start the loadingBar
  !config.disableLoading && iView.LoadingBar.start()
  let token = ''
  try {
    // console.log(`Start acquire token --------- ${config.url}`)
    token = !config.url.includes('login')
      ? process.env.NODE_ENV === 'production' ? getToken() // await authentication.acquireToken()
        : getToken()// valid the token
      : getToken()
    // console.log(`End acquire token --------- ${config.url}`)
  } catch (error) {
    iView.LoadingBar.error()
    // console.error(`Error acquire token --------- ${error}`)
  }
  config.url = (config.prefix || settings.request.prefix) + config.url // + (config.suffix || settings.request.suffix)
  // config.cancelToken = new axios.CancelToken(function executor(c) {
  // })
  // 为get请求添加时间戳,为了避免ie下get请求缓存
  if (config.method === 'get') {
    config.params = config.params || {}
    if (typeof config.params === 'string') {
      config.url += '?t=' + new Date().getTime()
    } else {
      config.params['t'] = new Date().getTime()
    }
  }
  // 发送request之前拦截请求,并添加token
  config.headers[settings.request.tokenId] = token
  config.headers['Token-Type'] = settings.request.tokenType
  // 客户端IP
  config.headers['Client-Public-Ip'] = window.sessionStorage.getItem('client.public.ip')
  config.headers['common'] = {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache'
  }
  // config.startTime = new Date()
  return config
}, error => {
  iView.LoadingBar.error()
  // 请求错误处理
  console.error(error) // for debug
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(
  response => {
    iView.LoadingBar.finish()
    return response
  },
  error => {
    if (!error.response) {
      console.error(error)
      return Promise.reject(error)
    }
    switch (error.response.status) {
      /**
       * 未验证
       * 1. 用户未登录, 没有token
       * 2. 已有token失效
       */
      case 401:
        if (!window.location.hash.includes('login')) {
          window[settings.tokenLocation].setItem(settings.request.errorTokenPageKey, window.location.hash.substr('1'))
        }
        // 如果login 401
        if (error.response.config.url.includes('login')) {
          localStorage.clear()
          authentication.signIn()
          break
        }
        router.push(settings.noAuthRedirect)
        iView.LoadingBar.error()
        break
      default:
        router.push(settings.errorRedirect)
        break
    }
    return Promise.reject(error)
  })

export default service
