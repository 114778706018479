import request from '@/utils/request'

export function pdiReport(data) {
  return request({
    url: 'business/v1/service/pdi/report/master',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}
export function getSalesRegionList() {
  return request({
    url: 'business/v1/service/pdi/region',
    method: 'get'
  })
}
export function getDealerNameList() {
  return request({
    url: 'business/v1/service/pdi/name',
    method: 'get'
  })
}

export function getDamagePartList() {
  return request({
    url: 'business/v1/service/pdi/parts',
    method: 'get'
  })
}

export function getDamageTypeList() {
  return request({
    url: 'business/v1/service/pdi/type',
    method: 'get'
  })
}

export function damageReport(data) {
  return request({
    url: 'business/v1/service/pdi/report/damage',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}
export function getDealerGroupList() {
  return request({
    url: 'business/v1/service/pdi/group',
    method: 'get'
  })
}

export function dealerReport(data) {
  return request({
    url: 'business/v1/service/pdi/report/dealer',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

export function subjectReport(data) {
  return request({
    url: 'business/v1/service/question-bank/report/subject',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

export function getResponsibilityList() {
  return request({
    url: 'statistics/v1/damage/responsibilities',
    method: 'get'
  })
}

export function topDamageReport(data) {
  return request({
    url: 'business/v1/service/pdi/report/top-damage',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

export function versionReport(params) {
  return request({
    url: 'business/v1/service/question-bank/export-version',
    method: 'post',
    responseType: 'arraybuffer',
    params
  })
}

export function getDamageLevelList() {
  return request({
    url: 'business/v1/service/pdi/level',
    method: 'get'
  })
}

export function getProvinceList() {
  return request({
    url: 'business/v1/service/pdi/province',
    method: 'get'
  })
}

export function damageDashboardReport(data) {
  return request({
    url: 'business/v1/damage/export-dashboard',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

export function questionAnalysisReport(data) {
  return request({
    url: 'business/v1/question/export-analysis',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}
