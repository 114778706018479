import { getQuestionList, getQuestionById, editQuestion, getQuestionCheckItemById, getLabelPageList, addLabel, deleteLabel,
  getVersion, addVersion, getVersionModel, getVersionFuel, checkVersionName, searchVersion, checkVersionModel, editVersion, disabledSubject, getLabelListInfo } from '@/apis/questionMgt'
const namespaced = true
/**
 * 初始值
 */
const state = {
  questionInfo: {},
  questionByIdInfo: {},
  questionCheckItem: true,
  versionInfo: {},
  versionModel: [],
  versionFuel: [],
  checkVersionName: true,
  searchVersionInfo: {},
  labelListInfo: {},
  labelPageInfo: {}
}

/**
 * 请求后台方法
 */

const actions = {
  /**
   * @param {}
   */
  async selectQuestionList({ commit }, queryParams) {
    const { data } = await getQuestionList(queryParams)
    commit('SET_QUESTION_INFO', data)
    return data
  },
  async selectQuestionById({ commit }, queryParams) {
    const { data } = await getQuestionById(queryParams)
    commit('SET_QUESTION_BY_ID_INFO', data)
    return data
  },
  async editQuestion({ commit }, queryParams) {
    const { data } = await editQuestion(queryParams)
    return data
  },
  async addLabel({ commit }, queryParams) {
    const { data } = await addLabel(queryParams)
    return data
  },
  async deleteLabel({ commit }, queryParams) {
    const { data } = await deleteLabel(queryParams)
    return data
  },
  async selectQuestionCheckItemById({ commit }, queryParams) {
    const { data } = await getQuestionCheckItemById(queryParams)
    commit('SET_QUESTION_CHECK_ITEM', data)
    return data
  },
  async selectVersionList({ commit }, queryParams) {
    const { data } = await getVersion(queryParams)
    commit('SET_VERSION_INFO', data)
    return data
  },
  async addVersion({ commit }, queryParams) {
    const { data } = await addVersion(queryParams)
    return data
  },
  async selectVersionModel({ commit }) {
    const { data } = await getVersionModel()
    commit('SET_VERSION_MODEL', data)
    return data
  },
  async selectVersionFuel({ commit }) {
    const { data } = await getVersionFuel()
    commit('SET_VERSION_FUEL', data)
    return data
  },
  async selectCheckVersionName({ commit }, queryParams) {
    const { data } = await checkVersionName(queryParams)
    commit('SET_CHECK_VERSION_NAME', data)
    return data
  },
  async selectCheckVersionModel({ commit }, queryParams) {
    const { data } = await checkVersionModel(queryParams)
    return data
  },
  async searchVersion({ commit }, queryParams) {
    const { data } = await searchVersion(queryParams)
    commit('SET_SEARCH_VERSION_INFO', data)
    return data
  },
  async editVersion({ commit }, queryParams) {
    const { data } = await editVersion(queryParams)
    return data
  },
  async disabledSubject({ commit }, queryParams) {
    const { data } = await disabledSubject(queryParams)
    return data
  },
  async getLabelListInfo({ commit }) {
    const { data } = await getLabelListInfo()
    commit('SET_LABEL_INFO', data)
    return data
  },
  async selectLabelList({ commit }, queryParams) {
    const { data } = await getLabelPageList(queryParams)
    commit('SET_LABEL_PAGE', data)
    return data
  }
}
/**
 * 复制
 */
const mutations = {
  SET_QUESTION_INFO(state, data) {
    state.questionInfo = data
  },
  SET_QUESTION_BY_ID_INFO(state, data) {
    state.questionByIdInfo = data
  },
  SET_QUESTION_CHECK_ITEM(state, data) {
    state.questionCheckItem = data
  },
  SET_VERSION_INFO(state, data) {
    state.versionInfo = data
  },
  SET_VERSION_MODEL(state, data) {
    state.versionModel = data
  },
  SET_VERSION_FUEL(state, data) {
    state.versionFuel = data
  },
  SET_CHECK_VERSION_NAME(state, data) {
    state.checkVersionName = data
  },
  SET_SEARCH_VERSION_INFO(state, data) {
    state.searchVersionInfo = data
  },
  SET_LABEL_INFO(state, data) {
    state.labelListInfo = data
  },
  SET_LABEL_PAGE(state, data) {
    state.labelPageInfo = data
  }
}
const getters = {
  questionInfo: state => {
    return state.questionInfo
  },
  questionByIdInfo: state => {
    return state.questionByIdInfo
  },
  questionCheckItem: state => {
    return state.questionCheckItem
  },
  versionInfo: state => {
    return state.versionInfo
  },
  versionModel: state => {
    return state.versionModel
  },
  versionFuel: state => {
    return state.versionFuel
  },
  checkVersionName: state => {
    return state.checkVersionName
  },
  searchVersionInfo: state => {
    return state.searchVersionInfo
  },
  labelListInfo: state => {
    return state.labelListInfo
  },
  labelPageInfo: state => {
    return state.labelPageInfo
  }
}
/**
 * 返回的数据
 */
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}

