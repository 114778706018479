import Vue from 'vue'

Vue.config.errorHandler = function(err, vm, info) {
  // const { name, fullPath, params, query } = vm.$route
  // const error = {
  //   err: err.stack,
  //   info,
  //   router: {
  //     name,
  //     fullPath,
  //     params,
  //     query
  //   },
  //   time: new Date()
  // }
  // vm.$setLogs('error', error)
  console.error(err, info)
}
